import { PropsWithChildren, useMemo } from 'react'
import { AbacProvider } from 'react-abac'
import { useUserPermissionsQuery } from 'types/domain'
import useApplicationRoleCache from 'components/business/hooks/useApplicationRoleCache'

const ABAC_ROLE = 'CURRENT_ROLE'

const PermissionsProvider = ({ children }: PropsWithChildren) => {
  const { applicationRole } = useApplicationRoleCache()
  const { data: userPermissionsData } = useUserPermissionsQuery()

  const currentPermissionMap = useMemo(() => {
    const accountPermission = userPermissionsData?.me?.accountUser?.permissions ?? []
    const applicationPermission = applicationRole?.permissions ?? []

    const permissions = [...accountPermission, ...applicationPermission]
    return permissions.reduce(function (map: { [x: string]: boolean }, permission) {
      map[permission] = true
      return map
    }, {})
  }, [applicationRole, userPermissionsData])

  const permissionRules: any = {
    [ABAC_ROLE]: currentPermissionMap,
  }
  const user = {
    roles: [ABAC_ROLE],
    permissions: [],
  }

  return (
    <AbacProvider user={user} roles={[ABAC_ROLE]} rules={permissionRules}>
      {children}
    </AbacProvider>
  )
}

export default PermissionsProvider
